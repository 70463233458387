import { useEffect, useRef, useState } from "react";
import { onResize } from "utils";
import SkewDiv from "components/general/SkewDiv";
import IMG from "assets/photos/living.webp";

const RATIO = 10;

export default function Living({
  srcTop,
  srcBtm,
  sizeTop,
  sizeBtm,
  className = "",
  updateOn = 0,
}) {
  const [counter, setCounter] = useState(0);
  const divRef = useRef<HTMLDivElement>();

  const sizeTopRef = useRef(sizeTop);
  sizeTopRef.current = sizeTop;

  const sizeBtmRef = useRef(sizeBtm);
  sizeBtmRef.current = sizeBtm;

  const styleTop = srcTop
    ? {
        background: "url('" + srcTop + "') 0%/var(--bgsize-t, 1280) repeat",
      }
    : {};

  const styleBtm = srcBtm
    ? {
        background: "url('" + srcBtm + "') 0%/var(--bgsize-b, 1280) repeat",
      }
    : {};

  const updateSize = () => {
    let div = divRef.current;
    let w = Math.max(div.offsetWidth, 100) / 1280 / RATIO;
    div.style.setProperty("--bgsize-t", w * sizeTopRef.current + "mm");
    div.style.setProperty("--bgsize-b", w * sizeBtmRef.current + "mm");
  };

  const updateAll = () => {
    updateSize();
    setCounter(i => i + 1);
  };

  useEffect(updateAll, [srcTop, srcBtm, sizeTop, sizeBtm, updateOn]);

  useEffect(() => {
    let offResize = onResize(updateSize);
    return offResize;
  }, [divRef]);

  return (
    <div
      ref={divRef}
      className={"relative select-none overflow-hidden " + className}
      style={{ contain: "paint" }}
      onDragStart={e => e.preventDefault()}
    >
      <SkewDiv
        corners={[
          2.15277778, 0.48751085, 2.79783951, 0.43334418, 3.35030864,
          0.59028863, 4.1712963, 0.50417752,
        ]}
        style={{
          ...styleBtm,
          width: "30%",
          height: "100%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-grey/30"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          -0.02006173, 0.71389974, 0.72273663, 0.48334418, 0.1867284,
          1.53473307, 1.65174897, 0.71528863,
        ]}
        style={{
          ...styleBtm,
          width: "90%",
          height: "100%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 w-1/2 left-1/2 bg-gradient-to-l from-grey/30"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          5.6484375, 0.15557304, 6.1015625, 0.27266906, 5.66927083, 1.03847702,
          6.09635417, 0.95416789,
        ]}
        style={{
          ...styleTop,
          width: "15%",
          height: "50%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-l from-white/10 to-grey/30"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          0.0, -0.17433183, 0.65114444, 0.11251028, -0.00157853, 0.78751028,
          0.6495659, 0.54145765,
        ]}
        style={{
          ...styleTop,
          height: "90%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-l from-grey/20"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          3.3140625, 0.41828547, 4.0015625, 0.06693412, 3.3171875, 1.0489161,
          4.0015625, 0.80567286,
        ]}
        style={{
          ...styleTop,
          width: "25%",
          height: "13%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-grey/20"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          6.078125, 0.27266906, 6.66666667, 0.23988217, 6.09375, 0.88391028,
          6.66666667, 0.91435524,
        ]}
        style={{
          ...styleTop,
          width: "15%",
          height: "50%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-l from-grey/20"></div>
      </SkewDiv>
      <img
        src={IMG}
        className="relative max-w-full min-w-[50px]"
        width="1920"
        height="1280"
        onLoad={updateAll}
        alt=""
      />
    </div>
  );
}
