import { useEffect, useRef, useState } from "react";
import { onResize } from "utils";
import SkewDiv from "components/general/SkewDiv";
import IMG from "assets/photos/kitchen.webp";

const RATIO = 10;

export default function Kitchen({
  srcTop,
  srcBtm,
  sizeTop,
  sizeBtm,
  className = "",
  updateOn = 0,
}) {
  const [counter, setCounter] = useState(0);
  const divRef = useRef<HTMLDivElement>();

  const sizeTopRef = useRef(sizeTop);
  sizeTopRef.current = sizeTop;

  const sizeBtmRef = useRef(sizeBtm);
  sizeBtmRef.current = sizeBtm;

  const styleTop = srcTop
    ? {
        background: "url('" + srcTop + "') 0%/var(--bgsize-t, 1280) repeat",
      }
    : {};

  const styleBtm = srcBtm
    ? {
        background: "url('" + srcBtm + "') 0%/var(--bgsize-b, 1280) repeat",
      }
    : {};

  const updateSize = () => {
    let div = divRef.current;
    let w = Math.max(div.offsetWidth, 100) / 1280 / RATIO;
    div.style.setProperty("--bgsize-t", w * sizeTopRef.current + "mm");
    div.style.setProperty("--bgsize-b", w * sizeBtmRef.current + "mm");
  };

  const updateAll = () => {
    updateSize();
    setCounter(i => i + 1);
  };

  useEffect(updateAll, [srcTop, srcBtm, sizeTop, sizeBtm, updateOn]);

  useEffect(() => {
    let offResize = onResize(updateSize);
    return offResize;
  }, [divRef]);

  return (
    <div
      ref={divRef}
      className={"relative select-none overflow-hidden " + className}
      style={{ contain: "paint" }}
      onDragStart={e => e.preventDefault()}
    >
      <SkewDiv
        corners={[
          3.6453202, 0.68202563, 4.36945813, 0.68346448, 5.24137931, 0.85612635,
          6.02955665, 0.80864433,
        ]}
        style={{
          ...styleBtm,
          width: "20%",
        }}
        updateOn={counter}
      ></SkewDiv>
      <SkewDiv
        corners={[
          0.00514403, 0.84211701, 0.99639918, 0.84362077, -0.08076132,
          1.11880874, 1.44084362, 1.13535009,
        ]}
        style={{
          ...styleBtm,
          width: "90%",
          height: "90%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-grey/10"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          0.0233463, 0.43229917, 1.05447471, 0.43518102, 0.02723735, 1.55045479,
          1.04669261, 1.54180926,
        ]}
        style={{
          ...styleTop,
          width: "76%",
          height: "50%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-white/20"></div>
      </SkewDiv>
      <img
        src={IMG}
        className="relative max-w-full min-w-[50px]"
        width="2558"
        height="1751"
        onLoad={updateAll}
        alt=""
      />
    </div>
  );
}
