import { Link } from "react-router-dom";
import { useTranslation } from "i18n";
import { useTileStore } from "./TileStoreProvider";
import { CATS } from "facts";

const PATH = process.env.REACT_APP_CDN_PATH;

export default function BannerSide() {
  const {
    data,
    idxTop,
    idxBtm,
    navIdxTop,
    navIdxBtm,
    catTop,
    catBtm,
    setCatTop,
    setCatBtm,
    allCats,
  } = useTileStore();

  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <aside className="min-w-[200px] text-xs md:text-sm leading-none text-center md:max-w-[200px] bg-stone-200 flex md:flex-col text-black py-2 px-1 md:px-4 gap-2 md:gap-4 justify-evenly items-center">
      {[
        { idx: idxTop, navIdx: navIdxTop, cat: catTop, setCat: setCatTop },
        { idx: idxBtm, navIdx: navIdxBtm, cat: catBtm, setCat: setCatBtm },
      ].map(({ idx, navIdx, cat, setCat }, i) => (
        <section
          key={i}
          className="flex items-center justify-between gap-1 md:flex-col md:gap-4"
        >
          {data[idx] ? (
            <img
              src={PATH + data[idx].src}
              className="mx-2 bg-mesh min-w-[13px] min-h-[13px] md:min-w-[49px] md:min-h-[49px] max-w-full max-h-[100px] lg:max-h-[150px] xl:max-h-[200px] shadow-sm shadow-stone-400 md:shadow-lg md:shadow-stone-500"
              style={{
                transform: "perspective(10cm) rotateY(15deg)",
              }}
              alt=""
            />
          ) : (
            <div
              className="mx-auto h-[13px] md:h-[49px] w-[13px] md:w-[49px] bg-mesh shadow shadow-stone-800 rounded-sm flex-1"
              style={{ transform: "perspective(10cm) rotateY(15deg)" }}
            ></div>
          )}

          <div className="space-y-2">
            <nav className="flex items-start justify-center gap-4 md:gap-6">
              <button
                className="px-2 py-0.5 md:px-4 hover:bg-cream bg-stone-300 active:text-white active:bg-stone-600 base-btn disabled:text-stone-400"
                onClick={() => navIdx(-1)}
              >
                &larr;
              </button>
              <button
                className="px-2 py-0.5 md:px-4 hover:bg-cream bg-stone-300 active:text-white active:bg-stone-600 base-btn disabled:text-stone-400"
                onClick={() => navIdx(1)}
              >
                &rarr;
              </button>
            </nav>

            <Link
              to={"/item/" + data[idx]?.id}
              className="block text-xs leading-none lg:leading-none lg:text-base hover:underline focus:underline"
              style={{ textShadow: "0 3px 3px rgba(0 0 0/0.1)" }}
            >
              {data[idx]?.desc || "-"}
            </Link>
            <div className="leading-none text-2xs sm:text-xs sm:leading-none">
              <div className="text-stone-700">
                {data[idx]?.w || "W"}
                <span className="text-2xs">&times;</span>
                {data[idx]?.h || "H"}
                {data[idx]?.t ? (
                  <span className="text-2xs">&times;</span>
                ) : null}
                {data[idx]?.t || ""}
                <span className="text-xs">mm</span>
              </div>

              {/* <div className="break-all text-stone-400 text-2xs">
                {data[idx]?.code || "-"}
              </div> */}
              <select
                value={cat}
                onChange={e => setCat(e.target.value)}
                className="w-20 mt-2 bg-transparent border rounded border-stone-300"
              >
                <option value="">{t("srch.all")}</option>
                {Object.entries(CATS)
                  .filter(([i]) => allCats.has(i))
                  .map(([code, names]) => (
                    <option key={code} value={code}>
                      {names[lang]}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </section>
      ))}
    </aside>
  );
}
