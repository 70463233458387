import { useMemo, useRef, CSSProperties } from "react";
import { shallow } from "zustand/shallow";
import { useTileStore } from "components/TileStoreProvider";
import { useSearchStore } from "components/SearchStoreProvider";
import TileBox from "components/TileBox";
import SortBar from "components/SortBar";
import { useTranslation } from "i18n";
import Reveal from "./general/Reveal";

const PAGE_SIZE = 12;

export default function TileList() {
  const topDivRef = useRef<HTMLDivElement>();

  const data = useTileStore(s => s.data);
  const [text, cat, type, width, height, page, setPage] = useSearchStore(
    s => [s.text, s.cat, s.type, s.w, s.h, s.page, s.setPage],
    shallow,
  );

  const matches = useMemo(() => {
    let w = parseFloat(width),
      h = parseFloat(height);
    return data.filter(
      i =>
        (!data || i.desc?.includes(text) || i.id?.includes(text)) &&
        (!cat || i.cat === cat) &&
        (!type || i.type === type) &&
        (!w || i.w === w) &&
        (!h || i.h === h),
    );
  }, [data, text, cat, type, width, height]);

  const pages = useMemo(
    () => matches.slice(page, page + PAGE_SIZE),
    [page, matches],
  );

  const visit = i => {
    setPage(i);
    topDivRef.current?.scrollIntoView();
  };

  const { t } = useTranslation();

  return (
    <main id="tiles" className="w-full max-w-screen-xl mx-auto grow">
      <h2 className="text-5xl font-light text-center text-cream">
        {t("srch.products")}
      </h2>

      <SortBar ref={topDivRef} />

      {pages.length > 0 ? (
        <ul
          className="grid gap-4 p-4 lg:p-8 sm:grid-cols-2 md:grid-cols-3"
          style={
            {
              "--swiper-navigation-sides-offset": "0px",
              "--swiper-navigation-size": "30px",
            } as CSSProperties
          }
        >
          {pages.map((tile, i) => (
            <TileBox key={tile.id} data={tile} />
          ))}
        </ul>
      ) : (
        <div className="h-[30vh] flex justify-center items-center flex-col text-center text-stone-300">
          <div className="p-4 px-8 border border-dashed rounded border-stone-300">
            {t("noMatch")}
          </div>
        </div>
      )}

      <nav className="flex items-center justify-center gap-4 mt-4 mb-8 md:gap-6">
        <button
          className="px-6 py-1 text-black md:px-8 bg-stone-300 base-btn disabled:opacity-30"
          onClick={() => visit(page - PAGE_SIZE)}
          disabled={page <= 0}
        >
          &larr;
        </button>
        <div>
          {Math.floor(page / PAGE_SIZE) + (matches.length > 0 ? 1 : 0)} of{" "}
          {Math.ceil(matches.length / PAGE_SIZE)}
        </div>
        <button
          className="px-6 py-1 text-black md:px-8 bg-stone-300 base-btn disabled:opacity-30"
          onClick={() => visit(page + PAGE_SIZE)}
          disabled={page + PAGE_SIZE + 1 >= matches.length}
        >
          &rarr;
        </button>
      </nav>
    </main>
  );
}
