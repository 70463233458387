import { useTranslation } from "i18n";
import Reveal from "components/general/Reveal";

export default function LotsOptions() {
  const { t } = useTranslation([], { keyPrefix: "banner" });
  return (
    <Reveal className="my-12 sm:my-24" style={{ "--anim-dur": "2s" }}>
      <aside className="flex flex-col-reverse items-center justify-center gap-8 overflow-hidden sm:flex-row">
        <img
          src={process.env.REACT_APP_CDN_PATH + "lots_options.webp"}
          alt="many options"
          className="shadow-lg sm:w-1/2 slide-r lg:rounded shadow-black/30"
          loading="lazy"
        />
        <div className="flex flex-col justify-center p-4">
          <h2 className="mb-4 text-xl sm:text-3xl slide-l">{t("varietyH1")}</h2>
          <div
            className="max-w-sm leading-relaxed text-justify slide-l"
            style={{ "--anim-delay": "0.2s" } as any}
          >
            {t("variety")}
          </div>
        </div>
      </aside>
    </Reveal>
  );
}
