import { useEffect } from "react";
import { useNavigate, Outlet, useParams } from "react-router-dom";
import { supportedLangs, useTranslation, extractPath, replaceRoot } from "i18n";

export default function LanguagePath() {
  const { i18n } = useTranslation();
  const slug = useParams()["lang"];
  const loc = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    if (slug && i18n.resolvedLanguage !== slug) {
      const path = extractPath(loc);
      if (supportedLangs.includes(slug)) {
        i18n.changeLanguage(slug, err => {
          if (err)
            navigate(replaceRoot(supportedLangs[0], path), { replace: true });
        });
      } else {
        navigate("/" + (i18n.resolvedLanguage || "en") + path, {
          replace: true,
        });
      }
    }
  }, [slug]);

  return <Outlet />;
}
