import { MdWhatsapp, MdPhoneIphone, MdEmail } from "react-icons/md";

export default function Contact() {
  return (
    <main className="flex flex-col w-full max-w-screen-xl mx-auto grow">
      <section className="flex flex-col items-center gap-8 p-4 py-12 lg:gap-12 sm:px-8 lg:flex-row">
        <div
          itemScope
          itemType="https://schema.org/Organization"
          className="pb-10 space-y-8 grow"
        >
          <h1
            className="grid pb-2 text-xl font-bold border-b-4 sm:grid-rows-2 sm:grid-flow-col gap-x-4 border-ltcream"
            itemProp="name"
          >
            <div lang="zh">賀臨貿易有限公司</div>
            <div lang="zh">賀臨建材公司</div>
            <div lang="en">CONAN TRADING COMPANY LIMITED</div>
            <div lang="en">CONAN BUILDING MATERIAL COMPANY</div>
          </h1>
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <MdPhoneIphone className="w-4 h-4" />
              <a
                href="tel:+85298399614"
                className="hover:underline focus:underline"
                itemProp="telephone"
              >
                +852-9839-9614
              </a>
              <a
                href="https://wa.me/85298399614/"
                className="bg-green-400 hover:bg-green-600 transition-colors rounded p-0.5 shadow shadow-black/20"
              >
                <MdWhatsapp className="w-5 h-5 fill-white" role="img" />
              </a>
            </div>
            <a
              href="mailto:kasper@conanhk.com?subject=Conan+website+inquiry"
              className="inline-flex items-center gap-2 hover:underline focus:underline"
              itemProp="email"
            >
              <MdEmail className="w-4 h-4" role="none" />
              kasper&#x40;conanhk.com
            </a>
          </div>
          <div
            className="space-y-2"
            itemProp="address"
            itemScope
            itemType="https://schema.org/PostalAddress"
          >
            <div lang="zh">
              九龍荔枝角青山道489至491號香港工業中心c座11樓5室
            </div>
            <div lang="en">
              Flat 5, 11/F, Block C, Hong Kong Industrial Centre,
              <br />
              489-491 Castle Peak Road, Lai Chi Kok, Kowloon, Hong Kong
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2838.399602531405!2d114.14758890356879!3d22.338201091877846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340407545a2ea955%3A0x634648584eaf992f!2sHong%20Kong%20Industrial%20Centre%20Block%20C%2C%20489-491%20Castle%20Peak%20Rd%2C%20Cheung%20Sha%20Wan!5e0!3m2!1sen!2shk!4v1684203692892!5m2!1sen!2shk"
          width="800"
          height="600"
          className="max-h-screen border border-stone-300 grow min-w-[200px] min-h-[200px] w-full lg:w-1/3 h-[500px] max-w-full shadow-lg shadow-black/30"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </section>
    </main>
  );
}
