import { useTranslation } from "i18n";
import Reveal from "components/general/Reveal";

export default function FamilyBanner({ className = "" }) {
  const { t } = useTranslation([], { keyPrefix: "banner" });
  return (
    <Reveal className={className}>
      <aside
        className="overflow-hidden xl:rounded text-black min-h-[260px] relative mt-4 flex flex-col justify-center items-end p-8 zoom-in"
        style={{
          background:
            "linear-gradient(90deg, rgba(220 220 220/0.3), rgba(220 220 220/0.3) 25%, rgba(220 220 220/1) 65%), url(" +
            process.env.REACT_APP_CDN_PATH +
            "happyfamily.jpg) 0% 35%/cover no-repeat",
        }}
      >
        <span className="block max-w-md ml-16 text-lg leading-relaxed text-right sm:w-1/2 md:text-xl">
          {t("human1")}
        </span>
        <span className="block mt-2 text-xl leading-relaxed text-right sm:text-2xl sm:w-2/3 md:text-4xl">
          {t("human2")}
        </span>
      </aside>
    </Reveal>
  );
}
