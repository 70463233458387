import { useState } from "react";
import { useTileStore } from "./TileStoreProvider";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Closet from "./rooms/Closet";
import Living from "./rooms/Living";
import Kitchen from "./rooms/Kitchen";
import BannerSide from "./BannerSide";

const PATH = process.env.REACT_APP_CDN_PATH;

export default function BannerTileCarousel() {
  const data = useTileStore(s => s.data);
  const idxTop = useTileStore(s => s.idxTop);
  const idxBtm = useTileStore(s => s.idxBtm);

  const tileUrlTop = idxTop >= 0 ? PATH + data[idxTop].tileSrc : null;
  const tileUrlBtm = idxBtm >= 0 ? PATH + data[idxBtm].tileSrc : null;

  const [counter, setCounter] = useState(0);

  return (
    <div className="flex flex-col-reverse xl:shadow-md md:flex-row xl:shadow-stone-400">
      <BannerSide />
      <Swiper
        autoHeight={true}
        grabCursor={true}
        modules={[Navigation, Pagination]}
        navigation
        pagination
        onResize={() => setCounter(i => i + 1)}
        className="max-w-full swiper-colors"
      >
        {[Closet, Living, Kitchen].map((Comp, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) =>
              isActive ? (
                <Comp
                  srcTop={tileUrlTop}
                  srcBtm={tileUrlBtm}
                  sizeTop={data[idxTop]?.w}
                  sizeBtm={data[idxBtm]?.w}
                  className="fade-in"
                  updateOn={counter}
                />
              ) : (
                <div className="w-full h-full">&nbsp;</div>
              )
            }
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
