export const CATS = {
  glaz: { en: "Glazed Tile", zh: "拋釉磚" },
  homo: { en: "Homogeneous/Porcelain", zh: "瓷磚" },
  marb: { en: "Nature Marble Stone 'n Granite", zh: "天然大理石及麻石" },
  mosa: { en: "Mosaic", zh: "馬實克" },
  outd: { en: "Outdoor", zh: "戶外" },
  sint: { en: "Sintered Stone", zh: "岩板" },
  tera: { en: "Terrazzo", zh: "水磨石" },
  timb: { en: "Timber", zh: "木紋磚" },
};

export const TYPES = {
  ansl: { en: "防滑 Anti Slip" },
  carv: { en: "雕刻 Carve" },
  loft: { en: "工業風 Loft" },
  marb: { en: "大理石 Marble" },
  mold: { en: "模具 Mold" },
  mosa: { en: "馬賽克 Mosaic" },
  rust: { en: "仿古 Rustic" },
  tera: { en: "水磨石 Terrazzo" },
  text: { en: "紋理 Textured" },
  tren: { en: "時尚 Trendy" },
  wood: { en: "木紋 Wood" },
};
