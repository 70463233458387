import { useTranslation } from "i18n";
import Reveal from "components/general/Reveal";

export default function WoodSamples() {
  const { t } = useTranslation([], { keyPrefix: "banner" });
  return (
    <Reveal className="my-12 sm:my-24" style={{ "--anim-dur": "2s" }}>
      <aside className="flex lg:min-h-[65vh] flex-col items-center justify-center overflow-hidden gap-8 sm:flex-row">
        <div className="flex flex-col justify-center p-4">
          <h2 className="mb-4 text-xl sm:text-3xl slide-r">
            {t("designedH1")}
          </h2>
          <div
            className="max-w-sm leading-relaxed text-justify slide-r"
            style={{ "--anim-delay": "0.2s" } as any}
          >
            {t("designed")}
          </div>
        </div>
        <img
          src={process.env.REACT_APP_CDN_PATH + "wood_samples.webp"}
          alt="thoughtfully designed"
          className="shadow-lg sm:w-1/2 slide-l lg:rounded shadow-black/30"
          loading="lazy"
        />
      </aside>
    </Reveal>
  );
}
