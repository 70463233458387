import { useEffect } from "react";
import BannerTileCarousel from "components/BannerTileCarousel";
import TileList from "components/TileList";
import FamilyBanner from "components/cosmetic/FamilyBanner";
import LotsOptions from "components/cosmetic/LotsOptions";
import QualityBanner from "components/cosmetic/QualityBanner";
import WoodSamples from "components/cosmetic/WoodSample";
import VSpacer from "components/VSpacer";

export default function Home() {
  const locHash = document.location.hash || "";

  useEffect(() => {
    document.getElementById(locHash.replace("#", ""))?.scrollIntoView();
  }, [locHash]);

  return (
    <main className="w-full max-w-screen-xl mx-auto grow">
      <BannerTileCarousel />
      <VSpacer />
      <TileList />
      <VSpacer />
      <FamilyBanner className="my-20" />
      <WoodSamples />
      <LotsOptions />
      <QualityBanner />
    </main>
  );
}
