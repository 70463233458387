import "./Main.css";
import { useRef, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "components/shared/Header";
import Footer from "components/shared/Footer";

const ANIM1 = {
  transformOrigin: "top",
  animation: "layout1-anim1 0.3s ease-in-out",
};
const ANIM2 = {
  transformOrigin: "top",
  animation: "layout1-anim2 0.3s ease-in-out",
};

export default function Layout() {
  const path = useLocation().pathname;

  const old = useRef(path);
  const klass = useRef(ANIM1);

  if (old.current !== path) {
    old.current = path;
    klass.current = klass.current === ANIM1 ? ANIM2 : ANIM1;
  }

  useEffect(() => {
    document.scrollingElement.scrollTop = 0;
  }, [path]);

  return (
    <>
      <Header />
      <div
        className="flex flex-col items-stretch grow body-layout"
        style={klass.current}
      >
        <Outlet />
        <Footer />
      </div>
    </>
  );
}
