import { create } from "zustand";

export type Tile = {
  id: string;
  vendor: string;
  cat: string;
  type: string;
  code: string;
  w: number;
  h: number;
  t: number;
  desc: string;
  src: string;
  tileSrc: string;
  largeSrc?: string;
  vendorSrc: string;
  miscSrc: string[];
};

type Store = {
  data: Tile[];
  setData: (d: Tile[]) => void;

  idxTop: number;
  idxBtm: number;
  navIdxTop: (dir: -1 | 0 | 1) => void;
  navIdxBtm: (dir: -1 | 0 | 1) => void;

  catTop: string;
  catBtm: string;
  setCatTop: (i: string) => void;
  setCatBtm: (i: string) => void;

  allWidths: number[];
  allHeights: number[];
  allCats: Set<string>;
  allTypes: Set<string>;
};

//create 2 preload elements to preload the prev/next tiles
const preloadLinks = Array(2)
  .fill(null)
  .map(n => {
    n = document.createElement("link");
    n.rel = "preload";
    n.as = "image";
    n.href = "data:image/gif,";
    //n.href will be added dynamically
    document.head?.appendChild(n);
    return n;
  });

function preloadImg(idx: number, src: string) {
  preloadLinks[idx].href = process.env.REACT_APP_CDN_PATH + src;
}

export const useTileStore = create<Store>((set, get) => ({
  data: [],
  setData: data => set({ data }),

  idxTop: -1,
  idxBtm: -1,

  navIdxTop: dir => {
    const { idxTop, data, catTop } = get();
    let i = idxTop;
    if (dir >= 0) {
      if (dir > 0) i++;
      for (; i < data.length; i++) {
        if (catTop && data[i].cat !== catTop) continue;
        set({ idxTop: i });
        break;
      }
      if (i >= data.length) {
        for (i = 0; i < idxTop; i++) {
          if (catTop && data[i].cat !== catTop) continue;
          set({ idxTop: i });
          break;
        }
      }
    } else if (dir < 0) {
      i--;
      for (; i >= 0; i--) {
        if (catTop && data[i].cat !== catTop) continue;
        set({ idxTop: i });
        break;
      }
      if (i < 0) {
        for (i = data.length - 1; i > idxTop; i--) {
          if (catTop && data[i].cat !== catTop) continue;
          set({ idxTop: i });
          break;
        }
      }
    }
    if (i > -1) {
      //preload next/prev tiles
      const data = get().data;
      preloadImg(0, data[i > 0 ? i - 1 : data.length - 1].tileSrc);
      preloadImg(1, data[i < data.length - 1 ? i + 1 : 0].tileSrc);
    }
  },

  navIdxBtm: dir => {
    const { idxBtm, data, catBtm } = get();
    let i = idxBtm;
    if (dir >= 0) {
      if (dir > 0) i++;
      for (; i < data.length; i++) {
        if (catBtm && data[i].cat !== catBtm) continue;
        set({ idxBtm: i });
        break;
      }
      if (i >= data.length) {
        for (i = 0; i < idxBtm; i++) {
          if (catBtm && data[i].cat !== catBtm) continue;
          set({ idxBtm: i });
          break;
        }
      }
    } else if (dir < 0) {
      i--;
      for (; i >= 0; i--) {
        if (catBtm && data[i].cat !== catBtm) continue;
        set({ idxBtm: i });
        break;
      }
      if (i < 0) {
        for (i = data.length - 1; i > idxBtm; i--) {
          if (catBtm && data[i].cat !== catBtm) continue;
          set({ idxBtm: i });
          break;
        }
      }
    }
    if (i > -1) {
      //preload next/prev tiles (ignores categories for simplicity)
      const data = get().data;
      preloadImg(0, data[i > 0 ? i - 1 : data.length - 1].tileSrc);
      preloadImg(1, data[i < data.length - 1 ? i + 1 : 0].tileSrc);
    }
  },

  catTop: "",
  catBtm: "",
  setCatTop: i => {
    set({ catTop: i });
    get().navIdxTop(0); //find nearest match possibly self
  },
  setCatBtm: i => {
    set({ catBtm: i });
    get().navIdxBtm(0); //find nearest match possibly self
  },

  allWidths: [],
  allHeights: [],
  allCats: new Set<string>(),
  allTypes: new Set<string>(),
}));

fetch(process.env.REACT_APP_CDN_PATH + "data.csv")
  .then(r => r.text())
  .then(str => {
    let tiles = new Map<string, Tile>();

    let allWidths = new Set<number>();
    let allHeights = new Set<number>();

    let allCats = new Set<string>();
    let allTypes = new Set<string>();

    for (let fileName of str.split("\n")) {
      //skip line breaks
      if (fileName.indexOf("_") < 0) continue;

      //truncate folder names and file extensions
      let fileSplit = fileName.slice(fileName.lastIndexOf("/") + 1);
      fileSplit = fileSplit.slice(0, fileSplit.lastIndexOf("."));

      let [vendor, cat, type, code, size, purpose, desc] = fileSplit.split("_");

      //"purpose" should be a 2 letter code. Report an error if it doesn't
      //match which might mean it was missing in the filename
      if (purpose?.length > 2) console.log("purpose length != 2", fileName);

      //vendor+code should be unique
      let id = encodeURIComponent(vendor + "_" + code);
      let tile = tiles.get(id);
      if (!tile) {
        //the tile's WIDTHxHEIGHTxTHICK, with THICK being optional
        let [w, h, t] = size.split("x").map<number>(i => parseFloat(i));

        //report an error if WIDTH or HEIGHT is not numeric
        if (!(w + h > 0)) console.log("WxH", fileName);
        tile = {
          id,
          vendor,
          cat,
          type,
          code,
          w,
          h,
          t,
          desc,
          src: "",
          tileSrc: "",
          vendorSrc: "",
          miscSrc: [],
        };

        //summarize some stats for the search dropdown menus
        allWidths.add(w);
        if (h) allHeights.add(h);
        allCats.add(cat);
        allTypes.add(type);
      } else if (desc && !tile.desc) {
        //we allow some filenames to drop the description
        tile.desc = desc;
      }
      switch (purpose) {
        case "sm": //small img
          tile.src = fileName;
          //can also act as the tile
          if (!tile.tileSrc) tile.tileSrc = fileName;
          break;
        case "lg": //large img
          tile.largeSrc = fileName;
          break;
        case "ti": //tiled img
          tile.tileSrc = fileName;
          //can also act as the main img
          if (!tile.src) tile.src = fileName;
          break;
        case "vd": //vendor (scene) img
          tile.vendorSrc = fileName;
          break;
        default:
          tile.miscSrc.push(fileName);
      }
      tiles.set(id, tile);
    }

    useTileStore.setState({
      data: [...tiles.values()],
      idxTop: 4,
      idxBtm: 1,
      allWidths: [...allWidths.values()],
      allHeights: [...allHeights.values()],
      allCats,
      allTypes,
    });
  });
