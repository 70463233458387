import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CATS, TYPES } from "facts";
import { useTranslation } from "i18n";

import { useSearchStore } from "components/SearchStoreProvider";
import { useTileStore } from "components/TileStoreProvider";

import Closet from "components/rooms/Closet";
import Living from "components/rooms/Living";
import Kitchen from "components/rooms/Kitchen";
import Reveal from "components/general/Reveal";

const PATH = process.env.REACT_APP_CDN_PATH;

export default function Item() {
  const params = useParams();
  const data = useTileStore(s => s.data);

  const id = params.id?.replace(/ /g, "%20");
  const item = useMemo(() => data.find(i => i.id === id), [id, data]);

  useEffect(() => {
    if (!item) {
      const n = document.head.appendChild(document.createElement("meta"));
      n.name = "robots";
      n.content = "noindex";
      return () => n.remove();
    }
  }, [item]);

  //use a different itemTop if the selected item is [timber]
  const itemTop = useMemo(
    () => (item?.cat === "timb" ? data.find(i => i.cat === "homo") : item),
    [data, item],
  );

  const setCat = useSearchStore(s => s.setCat);
  const setType = useSearchStore(s => s.setType);
  const navigate = useNavigate();

  const searchCat = () => {
    setCat(item?.cat);
    navigate("../#tiles");
  };

  const searchType = () => {
    setCat("");
    setType(item?.type);
    navigate("../#tiles");
  };

  const { t } = useTranslation([], { keyPrefix: "item" });

  return (
    <main className="w-full max-w-screen-xl mx-auto grow">
      <h2 className="my-8 text-5xl font-thin text-center text-cream">
        {t("title")}
      </h2>
      <div className="flex justify-center px-2 my-4 md:text-lg md:my-8 md:px-4">
        <dl className="grid grid-cols-2 gap-1 px-4 pb-4 border-b gap-x-4 md:gap-x-16 border-stone-200">
          <dt className="text-stone-300">{t("desc")}</dt>
          <dt className="text-stone-300">
            {t("code")}
            <span className="ml-1 text-stone-300">({item?.vendor})</span>
          </dt>
          <dd className={item ? "font-bold" : "placeholder"}>{item?.desc}</dd>
          <dd className={item ? "font-bold" : "min-w-[15ch] placeholder"}>
            {item?.code}
          </dd>
          <dt className="text-stone-300">{t("size")}</dt>
          <dt className="text-stone-300">{t("type")}</dt>
          <dd className={item ? "font-bold" : "px-2 placeholder"}>
            {item?.w || "…"}
            <span className="mx-1 text-xs">&times;</span>
            {item?.h || "…"}
            {item?.t ? (
              <>
                <span className="mx-1 text-xs">&times;</span>
                {item.t}
              </>
            ) : null}
            <span className="ml-1 text-xs">mm</span>
          </dd>
          <dd className={item ? "font-bold" : "placeholder"}>
            <button
              className="hover:underline focus:underline"
              onClick={searchType}
            >
              {TYPES[item?.type]?.en}
            </button>{" "}
            (
            <button
              className="hover:underline focus:underline"
              onClick={searchCat}
            >
              {CATS[item?.cat]?.en}
            </button>
            )
          </dd>
        </dl>
      </div>

      <div className="flex flex-wrap items-start justify-center gap-4 p-4 md:gap-8">
        {item?.src ? (
          <img
            src={PATH + item?.src}
            className="object-contain max-w-full border shadow-lg border-stone-200 shadow-stone-400 min-h-[49px] min-w-[40px] max-h-[100vmax]"
            loading="lazy"
            alt="tile"
          />
        ) : (
          <aside className="w-60 h-72 placeholder"></aside>
        )}
        {item?.vendorSrc ? (
          <Reveal className="zoom-in">
            <img
              src={PATH + item?.vendorSrc}
              className="object-contain max-w-full border shadow-lg border-stone-200 shadow-stone-400 min-h-[49px] min-w-[40px] max-h-[100vmax]"
              loading="lazy"
              alt="scenery shot"
            />
          </Reveal>
        ) : null}
        {item?.miscSrc?.map(i => (
          <Reveal key={i} className="zoom-in">
            <img
              src={PATH + i}
              alt="virtual scenery"
              loading="lazy"
              className="max-w-full min-w-[49px] min-h-[49px]"
            />
          </Reveal>
        ))}
      </div>

      {item
        ? [Closet, Living, Kitchen].map((Comp, idx) => (
            <Reveal key={idx} className="zoom-in">
              <Comp
                srcTop={PATH + itemTop?.tileSrc}
                srcBtm={PATH + item?.tileSrc}
                sizeTop={itemTop?.w}
                sizeBtm={item?.w}
                className="m-4 shadow-lg md:m-8 shadow-stone-400"
              />
            </Reveal>
          ))
        : null}
    </main>
  );
}
