export function onResize(cb: () => void) {
  let timer: ReturnType<typeof setTimeout>;
  const fn = () => {
    //cb();
    clearTimeout(timer);
    timer = setTimeout(cb, 100);
  };
  window.addEventListener("resize", fn);
  return () => {
    window.removeEventListener("resize", fn);
  };
}

export function cloneWithProps<T, K extends keyof T>(source: T, props: K[]) {
  let clone: Partial<T> = {};
  for (let n of props) {
    clone[n] = source[n];
  }
  return clone as Pick<T, K>;
}
