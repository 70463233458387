import logo from "assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import { useTranslation, langNames, replaceRoot, extractPath } from "i18n";

import { MdOutlineLanguage } from "react-icons/md";

const LINKS = [
  { to: "/", key: "products" },
  { to: "/profile", key: "profile" },
  { to: "/blog", key: "blog" },
  { to: "/portfolio", key: "portfolio" },
  { to: "/vrhome", key: "vrhome" },
  { to: "/contact", key: "contact" },
];

export default function Header() {
  const path = extractPath(window.location.href);
  const { t, i18n } = useTranslation([], { keyPrefix: "head" });
  const lang = i18n.resolvedLanguage;
  return (
    <header
      className="sticky top-0 z-10 border-b bg-white/80 border-stone-200"
      style={{
        backdropFilter: "blur(4px)",
      }}
    >
      <div className="flex items-center justify-between w-full max-w-screen-xl gap-1 p-1 px-2 mx-auto sm:px-4 md:py-4">
        <Link to="/" className="shrink-0" style={{ perspective: "10cm" }}>
          <img
            src={logo}
            alt="Conan"
            className="max-h-[45px] md:max-h-[65px] hover-rotate-y-360"
            style={{ aspectRatio: "400/301" }}
          />
        </Link>
        <nav className="grid items-center justify-center grid-flow-col grid-rows-2 py-2 text-sm uppercase sm:grid-rows-1 sm:text-sm md:text-base gap-x-4 sm:gap-x-6 gap-y-1 md:gap-y-2 grow">
          {LINKS.map(i => (
            <NavLink
              key={i.to}
              to={"/" + lang + i.to}
              end
              className={({ isActive }) =>
                "md:py-1 md:px-1 border-b-4 border-ltcream hover:border-cream/70 focus:text-cream hover:text-cream " +
                (isActive ? "border-cream text-cream" : "")
              }
            >
              {t(i.key)}
            </NavLink>
          ))}
        </nav>
        <Link
          className="flex flex-col items-center gap-1 text-xs leading-none sm:text-sm sm:flex-row text-stone-400 hover:underline hover:text-cream focus:text-cream"
          to={replaceRoot(lang === "zh" ? "en" : "zh", path)}
        >
          <MdOutlineLanguage /> {lang === "zh" ? langNames.en : langNames.zh}
        </Link>
      </div>
    </header>
  );
}
