import { useState, useEffect } from "react";
import zh from "./locales/zh/translation.json";
import en from "./locales/en/translation.json";

export const supportedLangs = ["zh", "en"];
export const langNames = { en: "EN", zh: "中" };

const resources: Record<string, Record<string, any>> = { zh, en };

const listeners = new Set<(i: any) => any>();

const i18n = {
  resolvedLanguage: supportedLangs[0], //pickFallback(supportedLangs),

  languages: supportedLangs,

  changeLanguage(s: string, callback?: (err?: any) => void) {
    if (supportedLangs.includes(s)) {
      this.resolvedLanguage = s;
      listeners.forEach(handler => handler((i: number) => i + 1));
      callback?.();
    } else callback?.(true);
  },
};

export function useTranslation(
  ns?: string | string[],
  opt = { keyPrefix: "" },
) {
  const update = useState(0)[1];
  useEffect(() => {
    listeners.add(update);
    return () => {
      listeners.delete(update);
    };
  }, []);

  return {
    i18n,

    t(key: string) {
      if (opt.keyPrefix) key = opt.keyPrefix + "." + key;

      const tokens = key.split(".");
      let res: any = resources[i18n.resolvedLanguage];
      while (res && tokens[1]) res = res[tokens.shift()];
      res = res?.[tokens[0]];

      return res || key;
    },
  };
}

function pickFallback(langs: string[]) {
  let l = navigator.language;
  if (langs.includes(l)) return l;
  l = l.slice(0, 2);
  if (langs.includes(l)) return l;
  return langs[0];
}

export function extractPath(url: string) {
  const u = new URL(url);
  return u.href.slice(u.origin.length);
}

export function replaceRoot(folder: string, path: string) {
  //possibilities:  /^ || /path^ || /path^/ || /path^/more
  let secondSlashPos = path.indexOf("/", 1);
  if (secondSlashPos < 0) secondSlashPos = path.length;
  const root = path.substring(1, secondSlashPos);
  if (supportedLangs.includes(root)) {
    return "/" + folder + path.slice(secondSlashPos);
  } else {
    return "/" + folder + path;
  }
}
