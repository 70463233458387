import { useEffect, useRef, useState } from "react";
import { onResize } from "utils";
import SkewDiv from "components/general/SkewDiv";
import IMG from "assets/photos/closet4.webp";

const RATIO = 10;

export default function Closet({
  srcTop,
  srcBtm,
  sizeTop,
  sizeBtm,
  className = "",
  updateOn = 0,
}) {
  const [counter, setCounter] = useState(0);
  const divRef = useRef<HTMLDivElement>();

  const sizeTopRef = useRef(sizeTop);
  sizeTopRef.current = sizeTop;

  const sizeBtmRef = useRef(sizeBtm);
  sizeBtmRef.current = sizeBtm;

  const styleTop = srcTop
    ? {
        background: "url('" + srcTop + "') 0%/var(--bgsize-t, 1280) repeat",
      }
    : {};

  const styleBtm = srcBtm
    ? {
        background: "url('" + srcBtm + "') 0%/var(--bgsize-b, 1280) repeat",
      }
    : {};

  const updateSize = () => {
    let div = divRef.current;
    let w = Math.max(div.offsetWidth, 100) / 1280 / RATIO;
    div.style.setProperty("--bgsize-t", w * sizeTopRef.current + "mm");
    div.style.setProperty("--bgsize-b", w * sizeBtmRef.current + "mm");
  };

  const updateAll = () => {
    updateSize();
    setCounter(i => i + 1);
  };

  useEffect(updateAll, [srcTop, srcBtm, sizeTop, sizeBtm, updateOn]);

  useEffect(() => {
    let offResize = onResize(updateSize);
    return offResize;
  }, [divRef]);

  return (
    <div
      ref={divRef}
      className={"relative select-none overflow-hidden " + className}
      style={{ contain: "paint" }}
      onDragStart={e => e.preventDefault()}
    >
      <SkewDiv
        corners={[
          -0.00205761, 0.88824678, 0.94238683, 0.89118796, -0.39401496,
          1.15152908, 1.30658436, 1.12648208,
        ]}
        style={{
          ...styleBtm,
          width: "90%",
          height: "90%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/20 via-grey/10"></div>
      </SkewDiv>

      <SkewDiv
        corners={[
          0.23529412, 0.20277296, 1.23242468, 0.20450607, 0.23672884, 1.2270364,
          1.23529412, 1.23570191,
        ]}
        style={{
          ...styleTop,
          width: "55%",
          height: "65%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-grey/10"></div>
      </SkewDiv>
      <SkewDiv
        corners={[
          9.49537037, 0.02493429, 10.06944444, -0.04517134, 9.48611111,
          1.01714613, 10.0787037, 1.06074766,
        ]}
        style={{
          ...styleTop,
          width: "10%",
          height: "85%",
        }}
        updateOn={counter}
      >
        <div className="absolute inset-0 bg-grey/10"></div>
      </SkewDiv>
      <img
        src={IMG}
        className="relative max-w-full min-w-[50px]"
        width="2161"
        height="1512"
        onLoad={updateAll}
        alt=""
      />
    </div>
  );
}
