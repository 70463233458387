import { useEffect, useRef } from "react";

export default function Reveal({
  children,
  rootMargin = "10%",
  className = "",
  ...rest
}) {
  const nodeRef = useRef<HTMLDivElement>();
  const classRef = useRef("");

  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;

    const obs = new IntersectionObserver(
      ([n]) => {
        const r = n.intersectionRatio;
        classRef.current = r <= 0 ? "hideanim " : "";
        node.className = classRef.current + className;
      },
      { rootMargin },
    );
    obs.observe(node);

    return () => {
      obs.unobserve(node);
    };
  }, []);

  return (
    <div ref={nodeRef} {...rest} className={classRef.current + className}>
      {children}
    </div>
  );
}
