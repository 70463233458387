import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Closet from "./rooms/Closet";
import Living from "./rooms/Living";
import Kitchen from "./rooms/Kitchen";

import type { Tile } from "components/TileStoreProvider";

const PATH = process.env.REACT_APP_CDN_PATH;

const hideOldSwipers = !window.location.search.includes("nohide=true");
let activeSwiper;

export default function TileBox({ data }: { data: Tile }) {
  const onBeforeSlideChangeStart = sw => {
    //reset all other TileBox swipers to hide the inactive SkewDivs because
    //apparently too many SkewDivs slow down the browser on iOS Safari
    if (activeSwiper !== sw && hideOldSwipers) {
      if (activeSwiper) activeSwiper.slideTo(0, 0, false);
      activeSwiper = sw;
    }
  };

  return (
    <li className="flex flex-col p-2 overflow-hidden border shadow-md over border-stone-200 lg:p-4 shadow-stone-300 aspect-square">
      <Swiper
        onBeforeSlideChangeStart={onBeforeSlideChangeStart}
        modules={[Navigation, Pagination]}
        navigation
        pagination
        loop
        className="flex-1 w-full overflow-hidden swiper-colors"
      >
        <SwiperSlide key="img" className="flex items-stretch overflow-hidden">
          <Link to={"item/" + data.id} className="overflow-hidden grow">
            <img
              src={PATH + data.src}
              className="object-cover w-full h-full placeholder grow"
              alt=""
            />
          </Link>
        </SwiperSlide>
        {data.vendorSrc ? (
          <SwiperSlide key="vnd" className="flex items-stretch overflow-hidden">
            <Link
              to={"item/" + data.id}
              className="overflow-hidden grow fade-in"
            >
              <img
                src={PATH + data.vendorSrc}
                className="object-cover w-full h-full placeholder grow"
                loading="lazy"
                alt=""
              />
            </Link>
          </SwiperSlide>
        ) : null}
        {[Closet, Living, Kitchen].map((Comp, idx) => (
          <SwiperSlide key={idx} className="flex items-center">
            {({ isActive }) =>
              isActive ? (
                <Link to={"item/" + data.id} className="relative grow fade-in">
                  <Comp
                    srcTop={PATH + data.tileSrc}
                    srcBtm={PATH + data.tileSrc}
                    sizeTop={data.w}
                    sizeBtm={data.w}
                    className="absolute inset-0"
                  />
                </Link>
              ) : null
            }
          </SwiperSlide>
        ))}
      </Swiper>

      <Link
        to={"item/" + data.id}
        className="py-1 my-1 text-sm text-center transition-colors hover:underline focus:underline lg:text-base"
      >
        {data.desc}
      </Link>

      <div className="flex flex-row items-end justify-between text-2xs lg:text-xs">
        <div className="text-stone-400">
          {data.w || "W"}
          <span className="text-xs">&times;</span>
          {data.h || "H"}
          {data.t ? (
            <>
              <span className="text-xs">&times;</span>
              {data.t}
            </>
          ) : null}
          <span className="text-xs">mm</span>
        </div>

        <div className="text-right text-stone-400">{data.code || "-"}</div>
      </div>
    </li>
  );
}
