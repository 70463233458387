import { create } from "zustand";
// import { useTileStore } from "./TileStoreProvider";

type Search = {
  text: string;
  w: string;
  h: string;
  cat: string;
  type: string;
  page: number;
  setText: (s: string) => void;
  setW: (s: string) => void;
  setH: (s: string) => void;
  setCat: (s: string) => void;
  setType: (s: string) => void;
  setPage: (i: number) => void;
};

export const useSearchStore = create<Search>(set => ({
  text: "",
  w: "",
  h: "",
  cat: "",
  type: "",
  page: 0,
  setText: text => {
    set({ text, page: 0 });
    // resetTileIdx();
  },
  setW: w => {
    set({ w, page: 0 });
    // resetTileIdx();
  },
  setH: h => {
    set({ h, page: 0 });
    // resetTileIdx();
  },
  setCat: cat => {
    set({ cat, page: 0, type: "" });
    // resetTileIdx();
  },
  setType: type => {
    set({ type, page: 0 });
    // resetTileIdx();
  },
  setPage: page => {
    set({ page });
  },
}));

// function resetTileIdx() {
//   useTileStore.setState(o => ({
//     idxTop: o.idxTop < 0 ? -1 : 0,
//     idxBtn: o.idxBtm < 0 ? -1 : 0,
//   }));
// }
