import { lazy, Suspense } from "react";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import {
  init as SentryInit,
  BrowserTracing,
  reactRouterV6Instrumentation,
  withSentryReactRouterV6Routing,
  Replay,
} from "@sentry/react";

import ErrorBoundary from "components/general/ErrorBoundary";
import LazyLoading from "components/general/LazyLoading";

import LanguagePath from "./components/general/LanguagePath";
import MainLayout from "components/layouts/Main";

import "./i18n";

//routes
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Item from "./pages/Item";
import NotFound from "./pages/NotFound";

SentryInit({
  dsn: "https://0d6aa8e756a3e128aba70119b0e4adf0@o4505989763629056.ingest.sentry.io/4505989771624448",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

//lazy loaded routes
const Portfolio = lazySuspense(() => import("./pages/Portfolio"));
const Blog = lazySuspense(() => import("./pages/Blog"));
const Profile = lazySuspense(() => import("./pages/Profile"));
const VRHome = lazySuspense(() => import("./pages/VRHome"));
const AI = lazySuspense(() => import("./pages/AI"));

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

function AppRoutes() {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path=":lang" element={<LanguagePath />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="item/:id" element={<Item />} />
            <Route path="profile" element={<Profile />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="vrhome" element={<VRHome />} />
            <Route path="blog/*" element={<Blog />} />
            <Route path="contact" element={<Contact />} />
            <Route path="ai" element={<AI />} />
          </Route>
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
}

function App() {
  return <AppRoutes />;
}

function lazySuspense(fn) {
  const Component = lazy(
    fn, //n => new Promise(ok => setTimeout(() => ok(fn()), 5000))
  );
  return function (props) {
    return (
      <ErrorBoundary>
        <Suspense fallback={<LazyLoading />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}

export default App;
