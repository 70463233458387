import styles from "./SkewDiv.module.css";
import { useRef, useEffect } from "react";
import { onResize } from "utils";

export default function SkewedDiv({
  corners = [
    //tl
    0, 0,
    //tr
    1, 0,
    //bl
    0, 1,
    //br
    1, 1,
  ],
  style = {},
  className = "",
  children = null,
  edit = false,
  updateOn = 0,
  ...props
}) {
  const divRef = useRef<HTMLDivElement>();

  //http://jsfiddle.net/dFrHS/1/
  //https://jsfiddle.net/kendfrey/oxm5L6q0/5/

  const render = () => {
    const div = divRef.current;
    const w = div.offsetWidth,
      h = div.offsetHeight;
    if (w > 0 && h > 0) {
      let c = corners.slice(0);
      for (let i = 0; i < 8; i += 2) {
        c[i] *= w;
        c[i + 1] *= h;
      }
      transform2d(div, c[0], c[1], c[2], c[3], c[4], c[5], c[6], c[7]);
    }
  };

  useEffect(render, [updateOn]);

  useEffect(() => {
    const div = divRef.current;

    let dragCorner = -1;

    const cleanup = () => {
      window.removeEventListener("mouseup", mouseUp);
      window.removeEventListener("mousemove", mouseMove);
    };

    let parentX = 0,
      parentY = 0;

    const mouseMove = (e: MouseEvent) => {
      if (dragCorner >= 0) {
        const w = div.offsetWidth,
          h = div.offsetHeight;
        if (w > 0 && h > 0) {
          corners[dragCorner] = (e.pageX - parentX) / w;
          corners[dragCorner + 1] = (e.pageY - parentY) / h;
          render();
        }
      }
    };

    const mouseUp = (e: MouseEvent) => {
      if (dragCorner >= 0) {
        dragCorner = -1;
        cleanup();
        console.log("[" + corners.map(i => i.toFixed(8)).join(",") + "]");
      }
    };

    const mouseDown = (e: MouseEvent) => {
      let rect = div.parentElement.getBoundingClientRect(),
        w = div.offsetWidth,
        h = div.offsetHeight,
        port = document.scrollingElement;

      parentX = rect.left + port.scrollLeft;
      parentY = rect.top + port.scrollTop;

      let min = Infinity;
      dragCorner = -1;
      for (let i = 0; i < 8; i += 2) {
        let dist =
          (e.pageX - parentX - corners[i] * w) ** 2 +
          (e.pageY - parentY - corners[i + 1] * h) ** 2;
        if (dist < min) {
          min = dist;
          dragCorner = i;
        }
      }
      mouseMove(e);
      window.addEventListener("mouseup", mouseUp);
      window.addEventListener("mousemove", mouseMove);
    };

    if (edit) div.addEventListener("mousedown", mouseDown);
    let offResize = onResize(render);

    render();

    return () => {
      offResize();
      if (edit) {
        div.removeEventListener("mousedown", mouseDown);
        cleanup();
      }
    };
  }, []);

  return (
    <div
      ref={divRef}
      className={styles.skewdiv + " " + className}
      style={edit ? { ...style, opacity: 0.8, zIndex: 10 } : style}
      {...props}
    >
      {children}
    </div>
  );
}

function transform2d<N extends HTMLElement>(
  node: N,
  x1,
  y1,
  x2,
  y2,
  x3,
  y3,
  x4,
  y4,
) {
  let w = node.offsetWidth,
    h = node.offsetHeight;
  let t = general2DProjection(
    [0, 0, x1, y1],
    [w, 0, x2, y2],
    [0, h, x3, y3],
    [w, h, x4, y4],
  );
  for (let i = 0; i < 9; ++i) t[i] = t[i] / t[8];
  let m = [
    [t[0], t[3], 0, t[6]],
    [t[1], t[4], 0, t[7]],
    [0, 0, 1, 0],
    [t[2], t[5], 0, t[8]],
  ];
  node.style.transform = "matrix3d(" + m.map(r => r.join(",")).join(", ") + ")";
}

function adjugate(m: number[]) {
  // Compute the adjugate of m
  return [
    m[4] * m[8] - m[5] * m[7],
    m[2] * m[7] - m[1] * m[8],
    m[1] * m[5] - m[2] * m[4],
    m[5] * m[6] - m[3] * m[8],
    m[0] * m[8] - m[2] * m[6],
    m[2] * m[3] - m[0] * m[5],
    m[3] * m[7] - m[4] * m[6],
    m[1] * m[6] - m[0] * m[7],
    m[0] * m[4] - m[1] * m[3],
  ];
}

function multMM(a: number[], b: number[]) {
  // multiply two matrices
  let c = Array(9);
  for (let i = 0; i < 3; ++i) {
    for (let j = 0; j < 3; ++j) {
      let cij = 0;
      for (let k = 0; k < 3; ++k) {
        cij += a[3 * i + k] * b[3 * k + j];
      }
      c[3 * i + j] = cij;
    }
  }
  return c;
}

function multMV(m: number[], v: number[]) {
  // multiply matrix and vector
  return [
    m[0] * v[0] + m[1] * v[1] + m[2] * v[2],
    m[3] * v[0] + m[4] * v[1] + m[5] * v[2],
    m[6] * v[0] + m[7] * v[1] + m[8] * v[2],
  ];
}

function basisToPoints(x1, y1, x2, y2, x3, y3, x4, y4) {
  let m = [x1, x2, x3, y1, y2, y3, 1, 1, 1];
  let v = multMV(adjugate(m), [x4, y4, 1]);
  return multMM(m, [v[0], 0, 0, 0, v[1], 0, 0, 0, v[2]]);
}

function general2DProjection(
  [x1s, y1s, x1d, y1d],
  [x2s, y2s, x2d, y2d],
  [x3s, y3s, x3d, y3d],
  [x4s, y4s, x4d, y4d],
) {
  let s = basisToPoints(x1s, y1s, x2s, y2s, x3s, y3s, x4s, y4s);
  let d = basisToPoints(x1d, y1d, x2d, y2d, x3d, y3d, x4d, y4d);
  return multMM(d, adjugate(s));
}
