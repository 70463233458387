import { forwardRef, Ref, useState } from "react";
import { shallow } from "zustand/shallow";
import { useSearchStore } from "./SearchStoreProvider";
import { useTileStore } from "./TileStoreProvider";
import { CATS, TYPES } from "facts";
import { useTranslation } from "i18n";

export default forwardRef<HTMLDivElement, any>(SortBar);

function SortBar(props: any, ref: Ref<HTMLDivElement>) {
  const d = useSearchStore();
  const [allWidths, allHeights, allCats, allTypes] = useTileStore(
    s => [s.allWidths, s.allHeights, s.allCats, s.allTypes],
    shallow,
  );

  const [input, setInput] = useState(d.text);
  const clearInput = () => {
    setInput("");
    d.setText("");
  };
  const onKeyEnter = e => {
    if (e.key === "Enter") d.setText(input);
  };

  const { t, i18n } = useTranslation([], { keyPrefix: "srch" });
  const lang = i18n.resolvedLanguage;

  return (
    <div className="flex flex-col items-center gap-4 mt-8 mb-2">
      <div className="flex flex-wrap items-center justify-center gap-2 gap-y-3 max-w-5xl min-h-[4rem] break-all px-1">
        {Object.entries(CATS).map(([n, v]) => (
          <button
            key={n}
            className={
              "p-1 px-4 text-stone-600 transition-all disabled:opacity-50 disabled:cursor-not-allowed " +
              (d.cat === n
                ? "py-4 rounded-lg mx-2 shadow-lg bg-ltcream"
                : "rounded bg-stone-200 hover:bg-ltcream focus:bg-ltcream")
            }
            onClick={() => d.setCat(d.cat === n ? "" : n)}
            disabled={!allCats.has(n)}
          >
            {v[lang] || v.en}
          </button>
        ))}
        <button
          className="px-1.5 text-sm disabled:invisible rounded hover:bg-cream bg-ltcream"
          onClick={() => d.setCat("")}
          disabled={!d.cat}
        >
          &times;
        </button>
      </div>
      <div ref={ref} className="flex justify-center">
        <div className="flex flex-wrap justify-center p-4 gap-x-4 gap-y-2 md:px-8">
          <div>
            <div className="mb-0.5 text-xs">{t("matter")}</div>
            <select
              value={d.type}
              onChange={e => d.setType(e.target.value)}
              className="p-1 px-2 border-4 rounded border-stone-300"
            >
              <option key="all" value="">
                {t("all")}
              </option>
              {Object.entries(TYPES).map(([n, v]) => (
                <option key={n} value={n} disabled={!allTypes.has(n)}>
                  {v[lang] || v.en}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="mb-0.5 text-xs">{t("w")} (mm)</div>
            <select
              value={d.w}
              onChange={e => d.setW(e.target.value)}
              className="p-1 px-2 border-4 rounded border-stone-300"
            >
              <option key="all" value="">
                {t("all")}
              </option>
              {allWidths.map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="mb-0.5 text-xs">{t("h")} (mm)</div>
            <select
              value={d.h}
              onChange={e => d.setH(e.target.value)}
              className="p-1 px-2 border-4 rounded border-stone-300"
            >
              <option key="all" value="">
                {t("all")}
              </option>
              {allHeights.map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div>
            <div className="mb-0.5 text-xs">{t("srch")}</div>
            <div className="flex items-center p-0.5 border-4 rounded border-stone-300 focus-within:outline">
              <button
                className={
                  "p-1 px-1.5 text-xs hover:bg-stone-200 focus:bg-stone-200 transition-opacity base-btn " +
                  (input ? "" : "hidden")
                }
                onClick={clearInput}
                disabled={!input}
              >
                &times;
              </button>
              <input
                type="text"
                value={input}
                onChange={e => setInput(e.target.value)}
                onKeyDown={onKeyEnter}
                className="w-24 md:w-36 px-0.5 outline-none"
                placeholder={t("srch") + "..."}
              />
              <button
                className={
                  "p-1 px-1.5 text-xs bg-stone-200 transition-opacity base-btn " +
                  (input ? "" : "opacity-0")
                }
                onClick={() => d.setText(input)}
                disabled={!input}
              >
                {t("find")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
