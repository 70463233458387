import * as React from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    console.error("ErrorBoundary", error);
    return { error };
  }

  render() {
    const _toNode = ({ error }) => (
      <div className="flex flex-col items-center justify-center min-h-full p-6 text-center">
        <div className="text-xl opacity-30">
          An exception was thrown by this page
        </div>
        <div className="max-w-md mt-5 font-mono text-sm italic break-words opacity-30">
          &ldquo;
          {error.message || error.code || error.toString()}
          &rdquo;
        </div>
      </div>
    );

    const error = this.state.error;
    if (error) {
      return _toNode({ error });
    }

    // return this.props.children;
    return (
      <SentryErrorBoundary fallback={_toNode}>
        {this.props.children}
      </SentryErrorBoundary>
    );
  }
}
